.contacts-pg {
  display: grid;
  width: 90vw;
  margin: 0 auto;
}

.contact-card {
  /* font-size: 1rem; */
  position: relative;
  /* display: flex; */
  /* margin: 0 auto; */
  /* border: 1px solid red; */
  /* align-items: center; */
}

.contact-card .address {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  line-height: 1.8;
  color: white;
  min-height: 400px;
  /* height: 400px; */

  /* overflow-y: scroll; */
  background-color: #2b6eb5;
  padding: 3rem;
  border-radius: 40px;
}

.address-icon {
  font-size: 1.5rem;
  vertical-align: baseline;
  position: relative;
  top: -4px;
}

@media (max-width: 700px) {
  /* .contact-img {
    max-width: 100%;
  } */
  .content-address {
    flex-direction: column;
  }
}

.content-address {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 2rem;
  align-items: center;
  /* border: 1px solid yellow; */
}

.contact-img img {
  width: 100%;
  /* height: auto; */
  height: 400px;
  border: 2px solid;
  border-radius: 10px;
}

.address-link {
  /* color: blue; */
  color: white;
}

.section-map {
  /* text-align: center; */
  margin-top: 1rem;
  margin-bottom: 3rem;
}
