.notice-list li a {
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.notice-container{
  width: 90%;
  margin: 0 auto;
}

.notice__navbar {
  margin: 0 auto;
  width: 90vw;
}

.notice-list li {
  list-style-type: none;
  padding: 0.8em;
  border-bottom-color: var(--secondary-color);
  border-bottom-style: solid;
}

.notice-list {
  border: none;
  flex-wrap: wrap;
  margin: 3vh 0;
  display: flex;
  justify-content: center;
}

/* pagination bar */

.pagination {
  margin: 2em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination span {
  color: blue;
  padding: 8px 16px;
  border: 1px solid #ddd;
}

.pagination span.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination span:hover:not(.active) {
  background-color: #ddd;
}

.pagination span:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination span:first-child:hover {
  cursor: default;
}

.pagination span:last-child:hover {
  cursor: default;
}

.pagination span:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* search */

.Notice-searchbox {
  margin: 0.6rem auto;
  display: flex;
  justify-content: center;
}

.Notice-searchbox input[type="text"] {
  min-width: 220px;
  border: none;
  outline: 2px solid blue;
  padding: 0.5rem;
}

.Notice-searchbox input:focus-within {
  background-color: lightyellow;
}

.nav-btn {
  border: none;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  background-color: white;
  cursor: pointer;
  padding: 8px 16px;
}

.nav-btn:hover {
  background-color: cadetblue;
  color: white;
}

.active-btn {
  background-color: #4caf50;
}
