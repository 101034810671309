.trmc-page {
  /* background-color: rgba(154, 212, 250, 0.448); */
  width: 90%;
  min-height: 20rem;
  margin: 0 auto;
}
.trmc-img {
  /* float: left; */
  /* height: 18em; */
  height: 300px;
  /* margin: 0px 10px 0px 0px; */
  /* padding-right: 0px; */
  /* padding-bottom: 0px; */
  max-width: 40%;
  /* max-height: 40%; */
  border: 2px solid;
  border-radius: 10px;
}

@media (max-width: 700px) {
  .trmc-img {
    max-width: 100%;
  }
}
.trmc-heading {
  text-align: center;
  padding: 1.5rem 0rem 1.5rem 0rem;
  font-size: 1.6rem;
}

.trmc-text {
  padding-right: 1em;
  text-align: justify;
  line-height: 1.8em;
  /* font-family: "Times New Roman", Times, serif; */
}
