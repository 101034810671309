.footer-wrapper {
  position: relative;
  text-align: left;
  border: 1px solid blue;
  background-color: var(--primary-color);
  color: whitesmoke !important;
  /* position: sticky; */
  left: 0;
  right: 0;
  overflow-x: auto;
  bottom: 0;
  width: 100%;
  height: 300px;
  font-family: sans-serif;
  line-height: 1.5;
}
.footer-content {
  display: flex;
  position: relative;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
  z-index: 2;
}

.links {
  display: flex;
  flex-direction: row;
}

.ft-link-title {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.item-about ul {
  list-style-type: square;
  padding-left: 1rem;
}

.item-about ul li a {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: white;
}

.item {
  padding: 1em;
  width: 300px;
  /* border: 1px solid white; */
}

@media (max-width: 700px) {
  .footer-wrapper {
    height: auto;
  }
  .footer-content {
    flex-direction: column;
    gap: 0.8rem;
  }
}

.item-campus .title {
  font-size: 1rem;
  margin-bottom: 8px;
}

.title {
  font-weight: 600;
}

.fb {
  text-decoration: none;
  padding: 8px;
  margin: 10px;
  display: inline-block;
  color: beige;
  transition: all 0.7s ease-in;
}

.fb:hover {
  color: orange;
  text-shadow: black 1px 1px 0;
}

.ft-list {
  list-style-type: square;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}

.ft-list a {
  transition: all 0.4s ease-in;
}
.ft-list a:hover {
  color: orange;
  text-shadow: black 1px 1px 0;
}

/* copyright message section */
.copyright-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.counterapi a {
  /* min-width: 100px; */
  /* padding-right: 10px; */
  color: white;
  font-weight: bold;
  text-decoration: none;
  /* padding-bottom: 10px; */
}

.copyright-section > p {
  padding: 2px 1em;
  font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif, Arial, Helvetica;
  letter-spacing: 2px;
  font-size: 13px;
}

.not-selectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.trademark {
  opacity: 0.2;
  position: absolute;
  top: 30%;
  z-index: 1;
  left: 20%;
  font-weight: bolder;
  right: 20%;
  translate: translate(-50%, 0);
  text-align: center;
  font-size: 3rem;
}
