.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: relative;
  top: 0;
  right: -50%;
  padding: 8px;
  font-size: 1.2em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

.modal-overlay {
  display: none;
}

.modal-overlay.active {
  display: flex;
}

.modal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  max-height: 800px;
  overflow: auto;
  align-items: center;
  /* background-color: #ffffff47; */
  padding: 24px;
}

.modal img {
  max-width: 80%;
  height: auto;
}

.modal p{
  font-weight: bold;
  color: white;
}
