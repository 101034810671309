.staff-container {
  display: grid;
  padding: 1rem;
  width: 90%;
  margin: 0 auto;
  gap: 1rem;
  overflow: auto;
  text-align: left;
}

.campus-authorities{
  width: 90%;
  min-height: 60vh;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.staff-information {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  line-height: 1.3em;
}

.staff-heading {
  padding: 1em;
  text-align: center;
  /* background-color: var(--primary-color);
  color: white; */
  font-size: large;
  font-weight: bold;
}

.staff-picture {
  width: 100%;
  max-width: 120px;
  max-height: 120px;
  height: auto;
  /* outline: 5px solid rgba(0, 0, 0, 0.2); */
  border-radius: 0.4em;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 900px) {
  .staff-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1600px) {
  .staff-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.staff-detail-card {
  display: grid;
  max-height: 200px;
  grid-template-columns: 150px 1fr;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.staff-detail-card:hover {
  box-shadow: 0 8px 16px 8px rgba(0, 0, 0, 0.2);
}

.staff-information {
  padding: 0.8rem;
}
