/* new */

.gallery-pg {
  min-height: 60vh;
  width: 90%;
  scroll-behavior: smooth;
  margin: 2rem auto;
}
.gallery-item {
  border-radius: 5px;
  /* box-shadow: 0px 0px 16px 2px rgba(10, 0, 0, 0.3); */
}

.gallery-item header {
  font-size: 1rem;
  line-height: 1.3;
  color: #333;
}

.gallery-item .gallery-desc {
  font-weight: 500;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  opacity: 0.9;
}

.gallery-item .gallery-date {
  font-weight: 500;
  font-family: monospace;
  /* opacity: .8; */
  font-weight: bold;
  font-size: 0.8rem;
}

.gallery-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr, 1fr)); */
  gap: 2rem;
}

.gallery-image-container {
  /* padding: 24px; */
  display: grid;
  max-width: 90%;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 0 auto;
  gap: 2rem;
}

.image-holder {
  /* margin: 0 auto; */
  position: relative;
  width: 300px;
  height: 200px;
  max-width: 340px;
  max-height: 200px;
  /* background-image: linear-gradient(blue, red); */
  /* background-color: #cccccc; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 1.2s ease-in;
  -moz-transition: all 1.2s ease-in;
  -o-transition: all 1.2s ease-in;
  -webkit-transition: all 1.2s ease-in;
}

.img-wrapper {
  display: inline-block;
  overflow: hidden;
}
.img-wrapper:hover .image-holder {
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  transform: scale(2);
}

.image-holder:hover {
  cursor: pointer;
  /* transform: scale(1.4); */
}

.overlay {
  /* display: "none"; */
  /* visibility: hidden; */
  position: absolute;
  bottom: 0%;
  width: 0px;
  height: 0px;
  right: 0%;
  background-image: linear-gradient(90deg, rgb(19, 172, 202), orange);
  transition: all 0.5s ease 0.1s;
  -moz-transition: all 0.5s ease 0.1s;
  -o-transition: all 0.5s ease 0.1s;
  -webkit-transition: all 0.5s ease 0.1s;
  opacity: 0.8;
}

.image-holder:hover .overlay {
  width: 340px;
  height: 200px;
  opacity: 0.4;
}

.galleryDialog {
  height: 600px;
  width: 800px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 25px rgba(36, 35, 35, 0.829);
  border-radius: 10px;
  border: none;
  outline: none;
}

.galleryDialog::backdrop {
  background: rgba(46, 45, 45, 0.849);
}

.galleryDialog span {
  float: right;
  cursor: pointer;
  font-size: larger;
  font-weight: bolder;
  font-size: 2rem;
  margin-right: 10px;
}


@media (max-width: 870px) {
  .galleryDialog {
    height: 400px;
    width: 600px;
  }
}
@media (max-width: 400px) {
  .galleryDialog {
    height: 250px;
    width: 450px;
  }
}
