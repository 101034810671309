.ssr_table table {
  border: 3px solid gray;
  margin: 0 auto;
  max-width: 80%;
  width: 80%;
  border-collapse: collapse;
  /* border-spacing: 20px; */
}

.ssr_table td {
  /* text-align: center; */
}
.ssr_table table td {
  padding: 10px;
  /* margin: 10px; */
}

.ssr_table {
  overflow: auto;
  max-height: 50vh;
}

/* CSS */
.button-41 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #00d775, #00bd68);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial,
    sans-serif;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 15%;
  z-index: 9;
  border: 0;
}

.button-41:hover {
  background: #00bd68;
}

/* CSS */
.button-62 {
  background: linear-gradient(to bottom right, #ef4765, #ff9a5a);
  border: 0;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-62:not([disabled]):focus {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5),
    0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

.button-62:not([disabled]):hover {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5),
    0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

/* .qaa-container {
  border: 1px solid goldenrod;
} */

.ssr_table thead {
  background-color: gainsboro;
  text-align: center;
}

.ssr_table th {
  padding: 6px 4px;
}

.qaa-password-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  /* background-color: lightblue; */
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: min-content;
  align-items: center;
  padding: 20px;
  border: 1px solid #888;
}

.close {
  color: #aaaaaa;
  position: relative;
  top: -25px;
  left: 50%;
  /* float: right; */
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
