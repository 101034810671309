.campus-message {
  display: flex;
  gap: 2rem;
}
.text-content {
  line-height: 1.5;
  flex-basis: 61%;
  overflow: auto;
  max-height: 35vh;
  padding: 0.5em;
  text-align: justify;
  font-family: 'Cormorant Garamond', serif;
}

@media (max-width: 800px) {
  .campus-message {
    flex-direction: column;
  }
  .campus-image-content {
    width: 100% !important;
  }
  .text-content {
    width: 100% !important;
    /* max-height: fit-content !important; */
    overflow: unset;
  }
}

.text-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.campus-image-content {
  flex-shrink: 0;
  border-radius: 20px;
  overflow: clip;
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
  flex-basis: 39%;
  height: 30vh;
}

.campus-image-content img {
  width: 100%;
  height: 100%;
}
