.menu-bar {
  /* variables */
  --nav-font-size: 1rem;
  --nav-highlight-color: orange;
  /* --nav-highlight-color: var(--secondary-color); */
  --sub-menu-font-size: 0.9rem;
  --nav-text-color: white;
  --nav-main-font: "Roboto", sans-serif;
  --nav-main-font-weight: 600;
  --nav-popup-bg-color: white;
  --bg-color-nav: #2f94ff; /*#2b6eb5;*/ /* main nav background color */
}

/* make any element sticky with this class. Probably should 
be placed in the root css file. */
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

/* menu root list items */
.menu-bar > ul {
  position: relative;
  list-style-type: none;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  --elevation: 8;
  font-family: var(--nav-main-font);
  font-weight: var(--nav-main-font-weight);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/* menu containing nav element */
.menu-bar {
  background-color: var(--bg-color-nav);
  transition: height 0.2s ease-in-out;
  -webkit-transition: height 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
}
/* ham icon for responsive page. */
.overflow-menu {
  position: absolute;
  top: 6px;
  display: none;
  right: 4px;
  font-size: 1.4rem;
  color: var(--nav-text-color);
  background-color: rgb(61, 93, 95, 0.6);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.overflow-menu:hover {
  background-color: var(--bg-color-nav);
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-bar > ul {
    display: none;
    max-width: 50%;
    flex-direction: column !important;
    align-items: flex-start;
    flex-wrap: nowrap !important;
    transition: height 3s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    transition: bottom 0.5s ease-in-out;
  }

  .overflow-menu {
    display: block !important;
  }
}
/* menu links */
.menu-item > div > a {
  text-decoration: none;
  color: var(--nav-text-color);
  display: inline-block;
  padding: 8px 10px;
  /* border: none; */
  transition: all 0.3s linear;
  /* border-bottom-width: 0px; */
}

.menu-item svg {
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
}

.menu-item > div:hover * {
  color: var(--nav-highlight-color);
}

.menu-bar .stop {
  border: none;
}

@keyframes popup {
  from {
    opacity: 0;
    /* scale: 0; */
  }
  to {
    opacity: 1;
    /* scale: 1; */
  }
}

/* popup container hidden by default */
.sub-menu-1 {
  display: none;
  min-width: 160px;
  max-width: fit-content;
  animation: popup 0.4s ease-in-out 0s 1 normal both;
  font-weight: 400;
}
/* show popup menu */
.menu-item:hover .sub-menu-1 {
  display: block;
  position: absolute;
  background-color: var(--nav-popup-bg-color);
  box-shadow: 0px 0px 4px 4px rgb(61, 93, 95, 0.4);
}

/* popup menu item settings */
.sub-menu-1 a {
  display: block;
  text-decoration: none;
  font-size: var(--sub-menu-font-size);
  padding: 8px 10px;
  text-align: left;
  color: #333 !important;
  word-wrap: break-word;
  transition: all 0.4s ease-in;
}

.lvl-one-item {
  list-style: none;
}

.lvl-one-item > a:hover {
  padding-left: 16px;
  color: var(--secondary-color) !important;
}
