.mgt-com-pg {
  width: 100%;
  min-height: 60vh;
  background-color: white;
  text-align: left;
  /* border: 2px solid black; */
}

.mgt-com-header {
  text-align: center;
  font-size: 14px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  opacity: 0.7;
  font-weight: 300;
}

.mgt-com-container {
  padding: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
