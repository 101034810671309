.strat-img {
  width: 400px;
  min-width: 300px;
  flex-shrink: 1;
  flex-grow: 0;
  align-self: center;
}

.strat-img img {
  width: 100%;
  height: auto;
}

.mission,
.vision,
.goles {
  display: flex;
  --elevation: 4;
}

.strategies-item-container {
  display: flex;
  /* border: 1px solid blue; */
  flex-direction: column;
}

.strategies-pg h2 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1em;
}

.strategies-pg .strat-main-text {
  color: #333;
  text-align: justify;
}

.strategies-pg {
  max-width: 90%;
  text-align: start;
  margin: 0 auto;
  margin-bottom: 3rem;
}

@media (max-width: 700px) {
  .mission,
  .vision,
  .goles {
    /* background-color: yellow; */
    flex-direction: column;
    /* --elevation: 24; */
  }
}

.strat-header {
  margin: 1rem 0;
  font-size: 24px;
  font-weight: 800;
  opacity: 0.9;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.strat-text {
  flex-grow: 1;
  flex-basis: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 2rem;
}
