body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  overflow-x: hidden;
}
/* number of lines to show */
.line-clamp {
  display: -webkit-box;
  line-clamp: var(--max-lines);
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main-heading {
  text-align: center;
  /* color: #67C1FF; */
}

.sub-heading {
  font-size: 1.2rem;
  text-align: center;
  margin: 2rem;
}


.color-white {
  color: white;
}

.text-center {
  text-align: center;
}
.hidden {
  display: none;
}
/* global margins */
.m-1 {
  margin: 1rem 0;
}
.m-2 {
  margin: 2rem 0;
}

.m-3 {
  margin: 3rem 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}
.card {
  --elevation: 0;
  --epx: calc(var(--elevation) * 1px);

  /* these 2 shadows serve as a border for  0-1 elevation    */
  --shadow1: 0 0 1px rgba(0, 0, 0, 0.1);
  --shadow2: 0 1px 2px rgba(0, 0, 0, 0.08);

  --offset-y: calc(var(--epx) + 1px);
  --blur: calc(var(--epx) * 2);
  --spread: calc(var(--epx) * 0.3);
  --shadow3: 0 var(--offset-y) var(--blur) var(--spread) rgba(0, 0, 0, 0.2);

  box-shadow: var(--shadow1), var(--shadow2), var(--shadow3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  scroll-behavior: smooth;
  box-sizing: border-box;
  --primary-color: #2F94FF; /*#3c6c9b;*/ /*#2b6eb5;*/
  --secondary-color: #ff6200;
  --color-accent: lightblue;
  --main-font-color: #333;
  --max-lines: 3;

}

.align-left{
  text-align: left;
}
/* layout classes */
.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

/* layout classes */

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secodary {
  background-color: var(--secondary-color);
}

/* predefined classes to reduce repetition */
.no-decoration {
  text-decoration: none;
}
.no-style {
  list-style: none;
}
.center {
  text-align: center;
}

.title {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 1em;
  font-size: 1.5rem;
  font-weight: 200;
}

.button-sm {
  background-color: var(--secondary-color);
  font-size: 1em;
}

.btn {
  text-decoration: none;
  padding: 0.5em;
  border: none;
  margin: 8px 0;
  display: inline-block;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

.btn:hover {
  background-color: gainsboro;
}

/* reset */

*,
::before,
::after {
  margin: 0;
  padding: 0;
  /* display: block; */
  box-sizing: inherit;
}

/* animations */
@keyframes fadein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
