.tbl-container {
  /* max-width: 80%; */
  overflow-x: auto;
}

.notice__table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
}

.notice__table th,
.notice__table td,
.notice__table tr {
  border: 1px solid black;
}

.notice__table tbody td {
  color: blue;
}

.notice__table tbody td:nth-child(1) {
  text-align: center;
}
.notice__table td {
  letter-spacing: 1px;
}

.notice__table th {
  text-align: center;
  background-color: var(--primary-color);
  color: white;
}

.notice__table td {
  font-family: Georgia, "Times New Roman", Times, serif;
  text-overflow: clip;
}

.notice__table td,
.notice__table th {
  padding: 1em;
}

/* table properties */

#row-filter tr td div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

#row-filter tr td div p{
  width: 100%;
}


#row-filter tr td div button{
  cursor: pointer;
}