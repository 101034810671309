.about-pg {
  /* background-color: rgba(154, 212, 250, 0.448); */
  width: 90%;
  margin: 0 auto;
}
.about-img {
  float: left;
  padding-right: 0rem;
  padding-bottom: 0rem;
  margin: 0px 8px 8px 0px;
  width: 100%;
  max-width: 40%;
  max-height: 40%;
  border: 2px solid;
  border-radius: 10px;
}

.about-heading {
  text-align: center;
  padding: 1.5rem 0rem 1.5rem 0rem;
  font-size: 1.6rem;
}

.about-text {
  padding-right: 1em;
  text-align: justify;
  line-height: 2em;
  /* font-family: "Times New Roman", Times, serif; */
}

.about-item {
  clear: both;
  display: flex;
  transition: all 1s linear;
  transform: scale(0);
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  /* .about-pg {
    /* background-color: rebeccapurple; */
}

.about-item-content {
  display: inline-block;
}

.about-pg {
  /* background-color: floralwhite; */
  padding-bottom: 2rem;
}

.image-with-text-box {
  display: inline-block;
}

/* this is for image with text */
.container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 1.5em;
}

.about-item.show {
  transform: scale(1);
}

.text-box {
  /* border: 1px solid slateblue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.img-box {
  align-items: center;
  display: flex;
}

.text-box > p {
  margin: 0 8px;
  text-align: center;
}

.about-links {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 98%;
  justify-content: space-between;
}

.about-links > a {
  font-size: 1.6rem;
}

.dropdown-container {
  text-align: center;
  margin-bottom: 2rem;
}
