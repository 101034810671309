.marquee {
  overflow: hidden;
  background: var(--primary-color);
}

.marquee p {
  width: 100%;
  margin: 0;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.3rem;
  /* color: var(--primary-color); */
  /* color: #000; */
  color: #fefefe;
  font-weight: 500;
  /* line-height: 2rem; */
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: scroll-left 15s linear infinite;
  -webkit-animation: scroll-left 15s linear infinite;
  animation: scroll-left 15s linear infinite;
}

.marquee p:hover {
  animation-play-state: paused !important;
  cursor: pointer;
}

/* Move it (define the animation) */
@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    /* Browser bug fix */
    -webkit-transform: translateX(100%);
    /* Browser bug fix */
    transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
    /* Browser bug fix */
    -webkit-transform: translateX(-100%);
    /* Browser bug fix */
    transform: translateX(-100%);
  }
}
