/* campus and carousel */
.campus-message-wrapper {
  max-width: 90%;
  /* outline: 1px solid black; */
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin: 2em auto;
}

/* campus & carousel */

.pub-container {
  max-height: 500px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
}

/* news */
.news-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  width: 90%;
  margin: 3rem auto;
}
/* news */

/* new slider for carousel */

.carou-message {
  display: flex;
  gap: 2rem;
}
.carou-text-content {
  line-height: 1.5;
  font-family: 'Cormorant Garamond', serif;
  overflow: auto;
  flex-basis: 61%;
  flex-shrink: 3;

  padding: 0.5em;
  text-align: justify;
}

.carou-text-content,
.ca-slider {
  height: 300px;
}

.ca-slider {
  position: relative;
  border-radius: 20px;
  overflow: clip;
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
  flex-basis: 39%;
}

.ca-prev,
.ca-next {
  top: 50%;
  color: white;
  position: absolute;
  font-size: 2.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s linear;
}

.ca-prev:active,
.ca-next:active {
  background-color: rgba(0, 0, 0, 1);
}

.ca-prev {
  left: 0;
}

.ca-next {
  right: 0;
}

.ca-img {
  width: 100%;
  transition: all 0.5s linear;
  max-width: 100%;
  height: 100%;
  animation-name: imageEffect;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: linear;
}

@keyframes imageEffect {
}

.indicator {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.indicator span {
  margin: 0 5px;
  display: inline-block;
  height: 6px;
  width: 30px;
  transition: all 0.5s linear;
  font-size: 2rem;
  border-radius: 4px;
  font-weight: bolder;
  cursor: pointer;
  background-color: white;
}
.indicator span.active {
  background-color: var(--primary-color);
}

@media (max-width: 800px) {
  .carou-message {
    flex-direction: column;
  }
  .ca-slider {
    width: 100% !important;
  }
  .carou-text-content {
    width: 100% !important;
    max-width: 100%;
    max-height: fit-content !important;
    overflow: unset;
  }
}
