.hm-notice-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: 100%;
  margin: 0 auto;
}
.hm-notice-item {
  display: flex;
  justify-content: flex-start;
  --elevation: 1;
  border-bottom: 2px solid white;
  margin: 4px 0px;
}

.hm-notice-item:first-child {
  margin: 0;
}

.hm-notice-content:last-child {
  margin: 0;
}
.date-container {
  background-color: #16436c;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 60px;
  width: 60px;
  height: 50px;
}

.hm-notice-item:hover {
  background-color: gainsboro;
}

.date-content {
  font-weight: bold;
}

.month-content {
  font-size: small;
  font-weight: 600;
}

.hm-notice-text {
  padding: 0px 8px;
}
