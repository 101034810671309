.em-association-pg {
  max-width: 90%;
  margin: 0 auto;
}

.em-content {
  display: grid;
  /* padding: .5rem; */
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 3rem;
}

.em-item {
  padding: 16px;
  font-family: Georgia, "Times New Roman", Times, serif;
  /* --elevation: 2; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  line-height: 1.2;
  color: #333;
}
.em-item:hover {
  box-shadow: 0 4px 8px 6px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.label-em {
  font-size: small;
  opacity: 0.8;
  font-weight: 600;
}

.mt-1{
  line-height: 1.8em;
}
