.SlideContainer {
  position: relative;
  margin: auto;
}

.SliderButtonBox {
  vertical-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: transparent;
}

.slide-img-hm {
  width: 100%;
}

@media (max-width: 700px) {
  .SlideImageContent {
    height: 300px !important;
  }
}

.SlideImageContent {
  width: 100%;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  height: 500px;
  animation: fadein 1s ease 0s 1 forwards;
  transition: all 0.5s linear;
}
.SlideImageContent {
  box-shadow: inset 0px -50px 50px 0px rgba(0, 0, 0, 0.6);
}

.PrevButton,
.NextButton {
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 3rem;
}

.PrevButton:hover,
.NextButton:hover:hover {
  /* color: black; */
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 760px) {
  .banner {
    /* width: 70% !important; */
    font-size: 1rem !important;
    text-align: left;
    padding: 0.3rem !important;
  }
  .PrevButton,
  .NextButton {
    font-size: 2.5rem;
  }
}

.dot {
  cursor: pointer;
  height: 0.8em;
  width: 0.8em;
  margin: 0 2px;
  background-color: var(--primary-color);
  border-radius: 50%;
  border-color: white;
  outline: 1px solid white;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot:hover {
  background-color: yellow;
}

.activated {
  background-color: white;
}

.dot-container {
  position: absolute;
  left: 50%;
  bottom: 3%;
  transform: translate(-50%, 0);
}

.banner {
  background-color: white;
  opacity: 0.8;
  position: absolute;
  bottom: 10%;
  max-width: 70vw;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.6rem;
}
