@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    /* border: 15px solid greenyellow; */
  }
}
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  background-color: white;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  gap: 10px;
}

.spinner-text {
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    color: greenyellow;
  }
  25% {
    color: blue;
  }
  45% {
    color: orangered;
  }
  100% {
    color: white;
  }
}

.loading-spinner {
  width: 75px;
  height: 75px;
  border: 15px solid #f3f3f3;
  border-top: 15px solid green;
  border-radius: 50%;
  animation: spinner 2.5s linear infinite;
}
