.chairman-page {
  line-height: 1.8em;
  width: 90%;
  margin: 0 auto;
}
.chairman-wrapper {
  margin: 0px 45px 0px 45px;
  padding: 4rem 0;
}

.chairman-wrapper p {
  text-align: justify;
}

.chairman-wrapper h2 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.clear {
  clear: left;
}

.chairman-img {
  width: 100%;
  float: left;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  max-width: 600px;
  max-height: 300px;
  border: 2px solid;
  border-radius: 10px;
}
