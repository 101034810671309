
.course-title {
  text-align: center;
  background-color: black;
  color: white;
  font-size: 20px;
}

.course-container {
  width: 100%;
  height: 650px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #2196F3;
  padding: 10px;
}

.sub-container{
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 600px;
  gap: 1rem;
}
.one{
  grid-row: 1/ spans 4;
  position: relative;
}

#test {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  /* width: 200px; */
  font-size: 30px;
  text-align: center;
}
/* #test .vertical-bar {
  background-color: black;
  color: white;
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;

  left: 0px;
  transform: rotate(90deg);
} */

.two, .three, .four, .five{
  height: max-content;
  position: relative;
}
