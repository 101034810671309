.teacher-pg {
  width: 90%;
  min-height: 60vh;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.teacher-item {
  margin: 0;
  padding: 0.5rem;
  --elevation: 6;
  border-radius: 10px;
  font-family: Poppin, serif, sans-serif;
}

.teacher-item:hover {
  box-shadow: 0 4px 8px 6px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.teacher-assoc {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 10px;
  gap: 1rem;
  text-align: left;
}

.mt-1{
  line-height: 1.8em;
}
