.academic-pg {
  text-align: justify;
  /* width: 90%; */
  /* background-color: rgba(154, 212, 250, 0.448); */
}

.academic-pg h2 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1em;
}

.academic-pg h4 {
  font-weight: bold;
  margin-left: 5em;
}

.academic-pg p {
  margin: 0.5em 5em 1em 5em;
}

.academic-pg ol {
  margin-left: 2em;
  padding: 0.5em;
}

ol li {
  font-size: initial;
  color: initial;
  font: initial;
}

.sub-item {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2F94FF;
}

.sub-item li {
  /* font-size: 1.2rem;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  font-size: 1.2rem;
  /* font-weight: bold; */
  /* color: brown; */
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  margin-left: 2rem;
}

.root-item {
  font-size: 1.3em;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #2F94FF;
}
