.publication-pg {
  width: 90vw;
  margin: 0 auto;
}

.publications-grid {
  display: grid;
  margin-top: 60px !important;
  /* background-color: beige; */
  /* margin: 0 auto; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  overflow: hidden;
  padding: 10px;
  /* width: 90vw; */
  /* border: 1px solid black; */
  gap: 1em;
  margin-bottom: 2em;
}

.pub-item {
  padding: 1rem;
  line-height: 1.6;
  /* border: 1px dotted tomato; */
  /* border-style:   ; */
  text-align: left;
  /* overflow: scroll; */
  overflow: clip;
  word-wrap: break-word;
  font-family: "Cormorant Garamond", serif;
  border-radius: 10px;
  --elevation: 8;
  transition: all 0.4s linear;
}

.pub-item:hover {
  /* transform: scale(104%); */
  background-color: aliceblue;
}

.pub-dl-btn {
  padding: 4px 16px;
  color: #333;
  background-color: orange;
  border-radius: 6px;
  border: none;
}
.pub-dl-btn:hover {
  background-color: lightcoral;
}
.pub-dl-btn:active {
  background-color: antiquewhite;
}

.pub-item-content {
  /* background-color: gainsboro; */
  /* border: 1px solid silver; */
  display: grid;
  /* text-justify: distribute-all-lines; */
  text-align: start;
  /* word-break; */
  grid-template-columns: 1fr 2fr;
}

/* .pub-item-content:nth-child(even) {
  background-color: white;
} */
