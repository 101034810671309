/* .nepali-dt > span {
  font-family: monospace;
  font-size: 1rem;
  background-color: white;
} */

@media (max-width: 768px) {
  .left-logo {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .logo__text {
    font-family: Roboto, sans-serif !important;
    font-weight: 600 !important;
    font-size: 0.8rem !important;
    color: #333;
    -webkit-text-stroke: unset !important;
  }
}

.header-top-bar {
  display: flex;
  margin: 0 8px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-top-bar button {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 2px;
  padding: 0 10px;
  border: none;
  border-radius: 2px;
  color: white;
  background-color: var(--primary-color);
}
.header-top-bar button:hover {
  background-color: orange;
}

.header-top-bar button:active {
  background-color: gray;
}

.header-top-bar div:last-child {
  font-size: 1rem;
  font-weight: 600;
}

.logo__container {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.logo__text {
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: var();
  -webkit-text-stroke: 1px #282828;
  /* text-shadow: 1px 0px 3px lightcyan; */
}

.no__wrap {
  white-space: nowrap;
}

/* language toggle */

.toggle-lang {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  width: 40px;
  align-items: center;
  height: 1.2em;
  cursor: pointer;
  outline: 1px solid gainsboro;
  background-color: var(--secondary-color);
}

.toggle-lang::before {
  content: "NP";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 1.2em;
  background-color: lightgray;
}

.toggle-lang::after {
  content: "EN";
  font-family: monospace;
  font-weight: 700;
  position: absolute;
  font-size: 0.9rem;
  color: whitesmoke;
  top: 50%;
  transform: translate(10%, -60%);
  left: 50%;
  transition: all 0.5s;
}

.toggle-lang:checked::before {
  left: 50%;
  content: "EN";
  transition: background 0.6s;
}

.toggle-lang:checked::after {
  content: "NP";
  color: whitesmoke;
  font-family: monospace;
  left: 0;
}

.logo__item img:hover {
  cursor: pointer;
}
