.news-events-item {
  padding: 0.4rem;
  position: relative;
  padding-bottom: 1rem;
  display: flex;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  /* border: 1px solid black; */
}

.news-events-item:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

.news-thumbnail {
  position: relative;
  top:20%;
  max-width: 150px;
  min-height: 100px;
  margin-right: 10px;
}
.news-dt {
  font-weight: 600;
  color: gray;
  font-size: 0.7rem;
  right: 0;
}

.news-text-content {
  text-align: justify;
  font-family: "Times New Roman", Times, serif;
  overflow: hidden;
  line-height: 1.4;
  max-lines: 3;
  overflow-wrap: break-word;
  font-size: 1rem;
  overflow-clip-margin: 20px;
}

.news-image {
  width: 100%;
  aspect-ratio: attr(width) / attr(height);
}
