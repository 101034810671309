@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;700&display=swap");

.main-content-section {
  min-height: 100vh;
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
}

.main-content-body {
    flex-grow: 1;
    min-height: calc(100vh - 300px);
}
