.committee-item {
  /* border: 1px solid blue; */
  --elevation: 2;
  padding: 1em;
  border: 0.5px dashed beige;
  font-size: 0.8rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* font-family: Georgia, "Times New Roman", Times, serif; */
}

.profilepic {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 16px;
  background-color: gainsboro;
}

.label {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black !important;
  font-size: 0.8rem;
  opacity: 0.5;
  font-weight: bolder;
}

.underConstructionPage h1 {
  text-align: center;
  color: red;
  margin: 150px 0px 100px 0px;
}

.value-text {
  color: black;
}