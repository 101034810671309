.trmc-page {
  margin-bottom: 4rem;
}

.trmc-content {
  display: flex;
  gap: 2rem;
  flex-wrap: nowrap;
}

.lib-slider {
  height: 300px;
  min-width: 300px;
  width: 500px;
  max-width: 500px;
}

.lib-slider {
  position: relative;
  border-radius: 20px;
  overflow: clip;
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 700px) {
  .lib-slider {
    width: 100% !important;
  }
  .trmc-content {
    flex-direction: column;
  }
}

.trmc-text {
  flex-basis: 65%;
  /* align-self: center; */
}

.lib-prev,
.lib-next {
  top: 50%;
  color: white;
  position: absolute;
  font-size: 2.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s linear;
}

.lib-prev:active,
.lib-next:active {
  background-color: rgba(0, 0, 0, 1);
}

.lib-prev {
  left: 0;
}

.lib-next {
  right: 0;
}

.lib-img {
  width: 100%;
  transition: all 0.5s linear;
  height: 100%;
  animation-name: imageEffect;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: linear;
}

@keyframes imageEffect {
}

.lib.indicator {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.lib.indicator span {
  margin: 0 5px;
  display: inline-block;
  height: 6px;
  width: 30px;
  transition: all 0.5s linear;
  font-size: 2rem;
  border-radius: 4px;
  font-weight: bolder;
  cursor: pointer;
  background-color: white;
}
.lib.indicator span.active {
  background-color: var(--primary-color);
}
