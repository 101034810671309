.pub-list {
  list-style-type: none;
  /* margin: 0.8rem; */
  border: none;
  overflow-y: auto;
  max-height: 400px;
}

.pub-list li {
  padding: 8px;
  /* box-shadow: 2px 0px 1px 0px rgba(0, 0, 0, 0.3); */
  /* margin: 2px; */
  /* border-bottom: 2px solid gainsboro; */
  --elevation: 0;
  margin: 4px;
  transition: all 0.5ms linear;
}

.pub-list li:first-child {
  margin: 0px 4px;
}


.pub-list li:last-child {
  margin: 0px 4px;
}

.pub-list li:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

.pub-date span {
  color: #333;
  font-size: 0.7rem;
}

.pub-text {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}

.pub-date {
  font-size: 0.7rem;
  color: #333;
  font-family: "Roboto", Tahoma, Geneva, Verdana, sans-serif;
}
